<template lang="pug">
  include /mixins
  +b.g-row.--appearance_spaced
    +b.g-cell.g-cols.--12-xs(
      v-for="(item, key) in levels"
      v-if="item.choices && item.choices.length"
      :class="{ 'g-cols--6-sm': isShort && !fullwidth }"
    )
      tag(
        :tag="$options.ModelChoiceFilter"
        :filter-name="key"
        :ref="key"
        :searchable="true"
        filter-label="name"
        :value="formdata[key] ? formdata[key].id : null"
        :filter="item"
        @input="getNestedItems($event, item, key)"
        @select="setValues($event, key)"
      )
    +b.g-cell.g-cols.--12-xs(v-if="showAsteriks")
      +b.ds-panel.--space_md
        +e.element.--offset_top
          +b.g-row.--space_xs.--appearance_spaced
            +b.g-cell.g-cols.--12-xs
              +b.P.ds-caption.--size_2xs.--color_dark {{ _("* - поле обов'язкове для заповнення") }}
            +b.g-cell.g-cols.--12-xs
              +b.P.ds-caption.--size_2xs.--color_dark {{ _("Оберіть спочатку область. Область є обов'язковим параметром для заповнення") }}
</template>

<script>
import { alphabetSorting } from '@utils/transformers'
import { geoDivisionsResource } from '@api/geo.service'
import SelectWidget from './SelectWidget'

const LEVELS = {
  level1: ['level2', 'level3', 'level4', 'level5'],
  level2: ['level3', 'level4', 'level5'],
  level3: ['level4', 'level5'],
  level4: [],
}
const NESTED_FIELDS = {
  level1: 'level2',
  level2: 'level3',
  level3: 'level4',
  level4: 'level5',
}

const one = 1

export default {
  ModelChoiceFilter: SelectWidget,

  props: [
    'value',
    'needAddressString',
    'isShort',
    'fullwidth',
    'showAsteriks',
  ],

  data() {
    return {
      firstLevel: {},
      levels: {},
      formdata: {},
      isCreated: true,
    }
  },

  watch: {
    value: {
      handler(nval) {
        if (!nval) {
          this.clearLevels()
          this.receiveAdditional()
        }
      },
    },
  },

  created() {
    this.receiveAdditional()
  },

  methods: {
    receiveAdditional() {
      geoDivisionsResource.execute().then(res => {
        const { data: { items } } = res

        this.firstLevel = items

        this.setAdditionalFilters()
        this.setDefaultValue()
      })
    },

    generateKey(index) {
      const level = index + one
      const key = `level${level}`

      return key
    },

    async setDefaultValue() {
      if (this.isCreated && this.value && this.value.length) {
        const values = JSON.parse(JSON.stringify(this.value))

        values.shift()

        Promise.all(values.map(async (val, index) => {
          const key = this.generateKey(index)
          const params = { parent_ids: val }

          await this.getLocationList(params, key)
          this.isCreated = false
        })).then(() => {
          values.forEach((val, index) => {
            const key = this.generateKey(index)
            const option = this.levels[key].choices.find(el => el.id === Number(val))
            this.setValues(option, key)
          })
        })
      }
    },

    setAdditionalFilters() {
      let choices = this.firstLevel.map(el => {
        el.value = el.id

        return el
      })

      choices = alphabetSorting(choices, 'name')

      const firstLevelTitle = this._('Уровень 1')

      const firstLevelTitlePrepared = this.showAsteriks ? `${firstLevelTitle} *` : firstLevelTitle

      this.levels = {
        level1: {
          label: firstLevelTitlePrepared,
          choices,
        },
        level2: {
          label: this._('Уровень 2'),
          choices: [],
        },
      }

      if (!this.isShort) {
        this.$set(this.levels, 'level3', {
          label: this._('Уровень 3'),
          choices: [],
        })
        this.$set(this.levels, 'level4', {
          label: this._('Уровень 4'),
          choices: [],
        })
        this.$set(this.levels, 'level5', {
          label: this._('Уровень 5'),
          choices: [],
        })
      }
    },

    clearLevels() {
      const fields = ['level1', 'level2', 'level3', 'level4', 'level5']
      fields.forEach(k => {
        const el = this.$refs[k]
        if (el && el[0]) {
          el[0].resetValue()
        }
      })
    },

    setValues(value, key) {
      this.$set(this.formdata, key, value)
    },

    resetFields(key) {
      const clearFields = LEVELS[key]
      if (clearFields) {
        clearFields.forEach(k => {
          if (this.levels[k]) {
            this.levels[k].choices = []
          }

          this.formdata[k] = null

          const field = this.$refs[k]

          if (field && field[0]) {
            field[0].resetValue()
          }

          this.$emit('input', { ...this.value, [k]: null })
        })
      }
    },

    getNestedItems(value, item, key) {
      this.resetFields(key)

      const [v] = value

      let path = []

      item.choices.forEach(el => {
        if (el.value === v) {
          path = el.path
        }
      })

      if (this.needAddressString) {
        this.$emit('input', {
          ...this.value,
          geo_path: path.join(','),
          address: this.getLevelsNames().join(' '),
        })
      } else {
        this.$emit('input', path.join(','))
      }

      const params = {}
      if (value && value.length) {
        params.parent_ids = value
        this.getLocationList(params, key)
      }
    },

    async getLocationList(params, key) {
      await geoDivisionsResource.execute(params).then(res => {
        const { data: { items } } = res

        let choices = items.map(el => {
          el.value = el.id

          return el
        })

        choices = alphabetSorting(choices, 'name')

        if (this.levels[NESTED_FIELDS[key]]) {
          this.levels[NESTED_FIELDS[key]].choices = choices
        }
      })
    },

    getLevelsNames() {
      return Object.keys(this.formdata).reduce((acc, k) => {
        if (this.formdata[k]) {
          let { name } = this.formdata[k]
          if ('level2' === k) {
            name = `${name} район`
          }
          if ('level1' !== k) {
            acc.push(name)
          }
        }
        return acc
      }, [])
    },
  },
}
</script>
